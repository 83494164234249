import request from "@/utils/request";

/**
 * 创建设备
 * @param params
 * @returns {*}
 */
export function createEquipment(params) {
  return request({
    url: "/api/backend/devices/type/create",
    method: "post",
    data: params
  });
}

/**
 * 查询设备信息
 * @param params
 * @returns {*}
 */
export function getEquipmentList(params) {
  return request({
    url: "/api/backend/devices/type/getList",
    method: "get",
    params
  });
}

/**
 * 查询功能列表【已废弃】
 * @param params
 */
export function getFuncList(params) {
  return request({
    url: "/api/backend/devices/type/getFuncList",
    method: "get",
    params
  });
}

/**
 * 删除功能【已废弃】
 * @param params
 * @returns {AxiosPromise}
 */
export function deleteFunc(params) {
  return request({
    url: "/api/backend/devices/type/deleteFunc",
    method: "post",
    data: params
  });
}

/**
 * 定价设置
 * @param params
 * @returns {*}
 */
export function setPrice(params) {
  return request({
    url: "/api/backend/devices/type/setPrice",
    method: "post",
    data: params
  });
}

/**
 * 查询定价列表
 * @param params
 */
export function getPriceList(params) {
  return request({
    url: "/api/backend/devices/type/getPriceList",
    method: "get",
    params
  });
}

/**
 * 切换定价状态
 * @param params
 * @returns {*}
 */
export function switchPriceStatus(params) {
  return request({
    url: "/api/backend/devices/type/switchPriceStatus",
    method: "post",
    data: params
  });
}

// =================== 智能设备 start =========================

/**
 * 查询设备列表
 * @param params
 * @returns {*}
 */
export function getDeviceList(params) {
  return request({
    url: "/api/backend/devices/device/getList",
    method: "get",
    params
  });
}

/**
 * 新增设备
 * @param params
 * @returns {*}
 */
export function createDevice(params) {
  return request({
    url: "/api/backend/devices/device/create",
    method: "post",
    data: params
  });
}

/**
 * 设备上线
 * @param params
 * @returns {*}
 */
export function goOnlineDevice(params) {
  return request({
    url: "/api/backend/devices/device/goOnline",
    method: "post",
    data: params
  });
}

/**
 * 小程序二维码
 * @param params
 * @returns {*}
 */
export function getWxQrcode(params) {
  return request({
    url: "/api/backend/devices/device/getWxQrcode",
    method: "get",
    params
  });
}

/**
 * 查询设备服务
 * @param params
 * @returns {*}
 */
export function getServeList(params) {
  return request({
    url: "/api/backend/devices/device/getServeList",
    method: "get",
    params
  });
}

/**
 * 添加设备服务
 * @param params
 * @returns {*}
 */
export function addServe(params) {
  return request({
    url: "/api/backend/devices/device/addServe",
    method: "post",
    data: params
  });
}
/**
 * 修改设备服务状态
 * @param params
 * @returns {*}
 */
export function updateServeStatus(params) {
  return request({
    url: "/api/backend/devices/device/updateServeStatus",
    method: "post",
    data: params
  });
}

/**
 * 查询服务状态日志
 * @param params
 * @returns {*}
 */
export function getServeLogList(params) {
  return request({
    url: "/api/backend/devices/device/getServeLogList",
    method: "get",
    params
  });
}


// =================== 智能设备 end =========================

// =================== 设备功能 start =========================

/**
 * 查询设备功能
 * @param params
 * @returns {*}
 */
export function getDeviceServeList(params) {
  return request({
    url: "/api/backend/devices/type/getDeviceServeList",
    method: "get",
    params
  });
}

/**
 * 创建设备功能
 * @param params
 * @returns {*}
 */
export function createDeviceServe(params) {
  return request({
    url: "/api/backend/devices/type/createDeviceServe",
    method: "post",
    data: params
  });
}

/**
 * 设置设备指令
 * @param params
 * @returns {*}
 */
export function setDeviceServeCommand(params) {
  return request({
    url: "/api/backend/devices/type/setDeviceServeCommand",
    method: "post",
    data: params
  });
}

/**
 * 定价设置
 * @param params
 * @returns {*}
 */
export function setServePrice(params) {
  return request({
    url: "/api/backend/devices/type/setServePrice",
    method: "post",
    data: params
  });
}

/**
 * 查询定价列表
 * @param params
 * @returns {*}
 */
export function getServePriceList(params) {
  return request({
    url: "/api/backend/devices/type/getServePriceList",
    method: "get",
    params
  });
}

/**
 * 查询设备指令
 * @param params
 * @returns {*}
 */
export function getDeviceServeCommand(params) {
  return request({
    url: "/api/backend/devices/type/getDeviceServeCommand",
    method: "get",
    params
  });
}

/**
 * 同步设备
 * @param params
 * @returns {*}
 */
export function synchDevices(params) {
  return request({
    url: "/api/backend/devices/device/synchDevices",
    method: "post",
    data: params
  });
}

// =================== 设备功能 end =========================

// =================== 设备报修 start =========================

/**
 * 查询设备报修
 * @param params
 * @returns {*}
 */
export function getReportrepairTagList(params) {
  return request({
    url: "/api/backend/devices/reportrepair/getTagList",
    method: "get",
    params
  });
}

/**
 * 创建设备报修标签
 * @param params
 * @returns {*}
 */
export function createReportrepairTag(params) {
  return request({
    url: "/api/backend/devices/reportrepair/createTag",
    method: "post",
    data: params
  });
}

/**
 * 删除设备报修标签
 * @param params
 * @returns {*}
 */
export function deleteReportrepairTag(params) {
  return request({
    url: "/api/backend/devices/reportrepair/deleteTag",
    method: "post",
    data: params
  });
}

/**
 * 查询报修列表
 * @param params
 * @returns {*}
 */
export function getReportrepairList(params) {
  return request({
    url: "/api/backend/devices/reportrepair/getList",
    method: "get",
    params
  });
}

// =================== 设备报修 end =========================

// ========================= 子设备 start =========================

/**
 * 添加子设备
 * @param params
 * @returns {AxiosPromise}
 */
export function addSub(params) {
  return request({
    url: "/api/backend/devices/device/addSub",
    method: "post",
    data: params
  });
}

/**
 * 删除子设备
 * @param params
 * @returns {AxiosPromise}
 */
export function deleteSub(params) {
  return request({
    url: "/api/backend/devices/device/deleteSub",
    method: "post",
    data: params
  });
}

/**
 * 查询子设备
 * @param params
 * @returns {*}
 */
export function getSubList(params) {
  return request({
    url: "/api/backend/devices/device/getSubList",
    method: "get",
    params
  });
}

// ========================= 子设备 end =========================
